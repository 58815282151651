<template>

    <div :class="$style['spotlight']">

        <div :class="$style['review']">

            <div :class="$style['review-header']">
                <div :class="$style['stars']">
                    <span v-for="i in 5"
                        :key="'star-' + i"
                        :class="[$style['icon'], 'fas fa-star']"
                        :style="{ 'color': i <= review.rating.score ? review.rating.color : 'rgba(0,0,0,0.2)' }">
                    </span>
                </div>
            </div>

            <div :class="$style['review-body']">
                <div :class="$style['text']">
                    <span v-html="text" />
                </div>
                <div v-if="hasAuthor" :class="$style['author']">
                    <v-avatar v-if="avatar"
                        :class="$style['avatar']"
                        :name="author.display_name"
                        :color="author.color"
                        :url="author.photo ? author.photo.link : author.gravatar"
                        :size="50"
                        :radius="25">
                    </v-avatar>
                    <div :class="$style['name']">{{ author.display_name }}</div>
                </div>
            </div>

            <div v-if="date" :class="$style['review-footer']">
                <span>{{ review.created_at | relativeTime(lang) }}</span>
            </div>

        </div>

        <v-badge v-if="params.ambassador === 1" />

    </div>

</template>

<style module lang="scss">

    .review {
        align-items: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        overflow: hidden;
        padding: 30px;
        position: relative;
    }

    .review-header {
        align-items: center;
        display: flex;
        .stars {
            line-height: normal;
            .icon {
                font-size: 26px;
                font-style: normal;
                margin-right: 5px;
            }
        }
    }

    .review-body {
        align-items: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-top: 15px;
        text-align: center;
        .text {
            font-family: var(--font);
            font-weight: 500;
            font-size: 20px;
            color: $color-text;
            line-height: 1.6em;
        }
        .author {
            align-items: center;
            display: flex;
            margin-top: 15px;
            .avatar {
                margin-right: 10px;
            }
            .name {
                color: $color-text;
                font-family: var(--font);
                font-size: 18px;
                font-weight: 600;
                line-height: 1.6em;
            }
        }
    }

    .review-footer {
        color: $color-muted;
        font-family: var(--font);
        font-size: 14px;
        font-weight: 500;
        line-height: 1.6em;
        margin-top: 15px;
        span {
            margin-left: 5px;
            &:first-child {
                margin: 0;
                &::before {
                    display: none;
                }
            }
            &::before {
                content: "•";
                display: inline;
                padding-right: 5px;
            }
        }
    }

</style>

<script>

import nl2br from 'nl2br';
import WidgetMixin from '@mixins/WidgetMixin';

export default {

    mixins: [
        WidgetMixin,
    ],

    props: {
        params: {
            type: Object,
            required: true,
        },
        review: {
            type: Object,
            required: true,
        },
    },

    data() {
        return {
            url: process.env.VUE_APP_URL,
        }
    },

    computed: {

        lang() {
            return this.params.lang !== undefined ? this.params.lang : 'en';
        },

        author() {
            return this.review.customer ?? this.review.reviewer;
        },

        source() {
            return this.review.source;
        },

        text() {
            let str = this.review.review ? nl2br(this.review.review) : '';
            if (str.length) return `&ldquo;${str}&rdquo;`;
            return str;
        },

        hasAuthor() {
            return this.author?.display_name;
        },

        // Parameters.

        avatar() {
            return this.params.avatar !== undefined ? Boolean(this.params.avatar) : true;
        },

        date() {
            return this.params.date !== undefined ? Boolean(this.params.date) : true;
        },

        fontFamily() {
            return this.params.font_family !== undefined ? this.params.font_family : 'Montserrat';
        },

    },

    mounted() {
        this.setFont(this.fontFamily);
    },

}

</script>