import axios from 'axios';
import { store } from '@/store';

export default function() {
    let settings = store.getters.settings;
    return axios.create({
        baseURL: process.env.VUE_APP_API_URL,
        headers: {
            'Authorization': settings.key ? 'Bearer ' + settings.key : '',
        }
    });
}
