// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".bb316f{-webkit-box-orient:vertical;-webkit-box-direction:normal;-ms-flex-direction:column;flex-direction:column;-webkit-box-pack:center;-ms-flex-pack:center;justify-content:center;overflow:hidden;padding:30px;position:relative}.bb316f,.cc6a29{-webkit-box-align:center;-ms-flex-align:center;align-items:center;display:-webkit-box;display:-ms-flexbox;display:flex}.cc6a29 .e38d21{line-height:normal}.cc6a29 .e38d21 .dcd938{font-size:26px;font-style:normal;margin-right:5px}.cb2f28{-webkit-box-align:center;-ms-flex-align:center;align-items:center;display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-orient:vertical;-webkit-box-direction:normal;-ms-flex-direction:column;flex-direction:column;-webkit-box-pack:center;-ms-flex-pack:center;justify-content:center;margin-top:15px;text-align:center}.cb2f28 .f35cdc{font-family:var(--font);font-weight:500;font-size:20px;color:#333;line-height:1.6em}.cb2f28 .ad721f{-webkit-box-align:center;-ms-flex-align:center;align-items:center;display:-webkit-box;display:-ms-flexbox;display:flex;margin-top:15px}.cb2f28 .ad721f .b78238{margin-right:10px}.cb2f28 .ad721f .c79200{color:#333;font-family:var(--font);font-size:18px;font-weight:600;line-height:1.6em}.e3d5cb{color:#999;font-family:var(--font);font-size:14px;font-weight:500;line-height:1.6em;margin-top:15px}.e3d5cb span{margin-left:5px}.e3d5cb span:first-child{margin:0}.e3d5cb span:first-child:before{display:none}.e3d5cb span:before{content:\"•\";display:inline;padding-right:5px}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"review": "bb316f",
	"review-header": "cc6a29",
	"stars": "e38d21",
	"icon": "dcd938",
	"review-body": "cb2f28",
	"text": "f35cdc",
	"author": "ad721f",
	"avatar": "b78238",
	"name": "c79200",
	"review-footer": "e3d5cb"
};
export default ___CSS_LOADER_EXPORT___;
