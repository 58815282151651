<template>

    <div v-if="reviews.length">

        <div id="masonry-reviews" :class="$style['reviews']">
            <div v-for="review in reviews" :key="review.id">
                <v-review
                    :lang="lang"
                    :review="review"
                    :avatar="avatar"
                    :date="date"
                    :border-color="borderColor"
                    :border-width="borderWidth"
                    :border-radius="borderRadius"
                    :drop-shadow="dropShadow"
                    :padding="padding"
                    :quotes="quotes"
                    :customer-position="customerPosition"
                    @changed="adjustLayout">
                </v-review>
            </div>
        </div>

        <v-badge v-if="params.ambassador === 1" />

    </div>

</template>

<style module lang="scss">

    .reviews {
        position: relative;
        > div {
            box-sizing: border-box;
            padding: 10px 10px;
            position: relative;
            width: 100%;
        }
    }

</style>

<script>

import Masonry from 'masonry-layout';
import WidgetMixin from '@mixins/WidgetMixin';

export default {

    mixins: [
        WidgetMixin,
    ],

    props: {
        params: {
            type: Object,
            required: true,
        },
        reviews: {
            type: Array,
            required: true,
        },
    },

    data() {
        return {
            masonry: null,
        }
    },

    computed: {

        lang() {
            return this.params.lang !== undefined ? this.params.lang : 'en';
        },

        avatar() {
            return this.params.avatar !== undefined ? Boolean(this.params.avatar) : true;
        },

        date() {
            return this.params.date !== undefined ? Boolean(this.params.date) : true;
        },

        borderColor() {
            return this.params.border_color !== undefined ? this.params.border_color : '#ccc';
        },

        borderWidth() {
            return this.params.border_width !== undefined ? this.params.border_width : '1px';
        },

        borderRadius() {
            return this.params.border_radius !== undefined ? this.params.border_radius : '5px';
        },

        dropShadow() {
            return this.params.drop_shadow !== undefined ? Boolean(this.params.drop_shadow) : false;
        },

        padding() {
            return this.params.padding !== undefined ? this.params.padding : '20px';
        },

        quotes() {
            return this.params.quotes !== undefined ? Boolean(this.params.quotes) : false;
        },

        customerPosition() {
            return this.params.customer_position !== undefined ? this.params.customer_position : 'bottom';
        },

        fontFamily() {
            return this.params.font_family !== undefined ? this.params.font_family : 'Montserrat';
        },

    },

    mounted() {
        this.setFont(this.fontFamily);

        // Init masonry.
        this.$nextTick(() => {
            this.updateMasonryColumns();
            this.masonry = new Masonry('#masonry-reviews', {
                percentPosition: true,
            });
        });

        // Adjust layout on page load.
        window.onload = () => {
            this.adjustLayout();
        }

        // Listeners.
        window.addEventListener('resize', this.updateMasonryColumns);
        window.addEventListener('scroll', this.adjustLayout);
    },

    beforeDestroy() {
        window.removeEventListener('resize', this.updateMasonryColumns);
        window.addEventListener('scroll', this.adjustLayout);
    },

    methods: {

        updateMasonryColumns() {
            this.$nextTick(() => {
                const container = document.querySelector('#masonry-reviews');
                if (!container) return;
                const containerWidth = container.offsetWidth;
                let cols = 1;
                if (containerWidth > 1200) {
                    cols = 4;
                } else if (containerWidth > 992) {
                    cols = 3;
                } else if (containerWidth > 768) {
                    cols = 2;
                }
                const itemWidth = Math.floor(containerWidth / cols);
                Array.from(container.children).forEach(item => {
                    item.style.width = `${itemWidth}px`;
                });
                if (this.masonry) {
                    this.masonry.layout();
                }
            });
        },

        adjustLayout() {
            if (this.masonry) {
                this.$nextTick(() => {
                    this.masonry.layout();
                });
            }
        },

    },

}

</script>