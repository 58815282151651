// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ab8e21{display:grid;overflow:hidden}.ab8e21 .d9f484{background-position:50%;background-repeat:no-repeat;background-size:cover;overflow:hidden}.ab8e21 .d9f484,.ab8e21 span{grid-column-start:1;grid-row-start:1}.ab8e21 span{color:#fff;display:grid;font-family:var(--font);font-size:9px;font-weight:700;line-height:0;place-items:center;text-transform:uppercase;-webkit-user-select:none;-moz-user-select:none;-ms-user-select:none;user-select:none}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"avatar": "ab8e21",
	"photo": "d9f484"
};
export default ___CSS_LOADER_EXPORT___;
