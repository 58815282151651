// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".c095cd{color:#333;font-family:Montserrat,Arial,sans-serif;font-size:13px;font-weight:500;margin-top:30px;text-align:center}.c095cd span{display:block;line-height:1.6em;margin-bottom:10px}.c095cd img{height:30px;width:auto}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"badge": "c095cd"
};
export default ___CSS_LOADER_EXPORT___;
