<template>

    <div></div>

</template>

<script>

import api from '@api/Api';
import LogMixin from '@mixins/LogMixin';
import Vue from 'vue';

export default {

    mixins: [
        LogMixin,
    ],

    mounted() {
        this.log('Reviews mounted.');
        window.addEventListener('load', () => {
            // Handle queue.
            if (typeof window.saastic !== 'undefined') {
                if (window.saastic.q) {
                    window.saastic.q.forEach((args) => {
                        this.handleCommand(args);
                    });
                }
            } else {
                if (window.reviews.q) {
                    window.reviews.q.forEach((args) => {
                        this.handleCommand(args);
                    });
                }
            }
            // Replace queue.
            if (typeof window.saastic !== 'undefined') {
                let self = this;
                window.saastic = function() {
                    self.handleCommand(arguments);
                }
            } else {
                let self = this;
                window.reviews = function() {
                    self.handleCommand(arguments);
                }
            }
        });
    },

    computed: {

        settings() {
            return this.$store.getters.settings;
        },

    },

    methods: {

        handleCommand(args) {
            let command = args[0];
            if (command === 'init') {
                this.handleInit(args[1]);
            } else if (command === 'wall') {
                this.handleWall(args[1]);
            } else if (command === 'spotlight') {
                this.handleSpotlight(args[1]);
            }
        },

        handleInit(key) {
            let settings = this.settings;
            settings.key = key;
            this.$store.commit('setSettings', settings);
        },

        handleWall(params) {
            let q = {
                limit: params.limit,
                score: params.score,
                sources: params.sources,
                locations: params.locations,
                tags: params.tags,
                name_convention: params.name_convention,
                review_chars: params.review_chars,
            };
            api().get('/public/reviews', { params: q }).then((response) => {
                let reviews = response.data.data;
                if (!reviews.length) return;
                // Get widget HTML.
                params.ambassador = parseInt(response.headers.get('X-Ambassador'));
                const widget = new Vue({
                    template: `<v-widget-wall :params="params" :reviews="reviews" />`,
                    data: () => ({
                        params: params,
                        reviews: reviews,
                    }),
                }).$mount();
                // Add to DOM.
                const el = document.getElementById(params.id);
                if (el) el.appendChild(widget.$el);
            }, (error) => {
                this.warn(error.response.data.message, error);
            });
        },

        handleSpotlight(params) {
            let q = {
                limit: 1,
                uuid: params.uuid,
                name_convention: params.name_convention,
            };
            api().get('/public/reviews', { params: q }).then((response) => {
                let reviews = response.data.data;
                if (!reviews.length) return;
                // Get widget HTML.
                params.ambassador = parseInt(response.headers.get('X-Ambassador'));
                const widget = new Vue({
                    template: `<v-widget-spotlight :params="params" :review="review" />`,
                    data: () => ({
                        params: params,
                        review: reviews[0],
                    }),
                }).$mount();
                // Add to DOM.
                const el = document.getElementById(params.id);
                if (el) el.appendChild(widget.$el);
            }, (error) => {
                this.warn(error.response.data.message, error);
            });
        },

    },

}

</script>