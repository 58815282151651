<template>

    <div :class="$style['review']" :style="{
        'border-color': borderColor,
        'border-width': borderWidth,
        'border-radius': borderRadius,
        'box-shadow': dropShadow ? '0 2px 5px rgba(0,0,0,0.3)' : 'none',
        'padding': padding,
    }">

        <div :class="$style['review-body']" :style="{ 'flex-direction': customerPosition === 'top' ? 'column-reverse' : 'column' }">
            <div>
                <div :class="$style['stars']">
                    <div>
                        <span v-for="i in 5"
                            :key="'star-' + i"
                            :class="[$style['icon'], 'fas fa-star']"
                            :style="{ 'color': i <= review.rating.score ? review.rating.color : 'rgba(0,0,0,0.2)' }">
                        </span>
                    </div>
                    <div v-if="source" :class="$style['source']" :title="source.name">
                        <img v-if="source.icon" :class="$style['icon']" :src="source.icon.link" height="20" width="20" />
                        <span v-else-if="source.sprite" :class="[$style['icon'], source.sprite]" :style="{ 'color': source.color }" />
                    </div>
                </div>
                <div v-if="canExpand" :class="$style['text']">
                    <template v-if="!expanded">
                        <span v-html="collapsedText" />
                        <span>... <a class="more-or-less" href="#" @click.prevent="expand">more</a></span>
                    </template>
                    <template v-else>
                        <span v-html="expandedText" />
                        <span>&nbsp;<a class="more-or-less" href="#" @click.prevent="collapse">less</a></span>
                    </template>
                </div>
                <div v-else :class="$style['text']">
                    <span v-html="expandedText"></span>
                </div>
            </div>
            <div v-if="hasAuthor" :class="$style['author']">
                <v-avatar v-if="avatar"
                    :class="$style['avatar']"
                    :name="author.display_name"
                    :color="author.color"
                    :url="author.photo ? author.photo.link : author.gravatar"
                    :size="50"
                    :radius="25">
                </v-avatar>
                <div :class="$style['name']">{{ author.display_name }}</div>
            </div>
        </div>

        <div v-if="date" :class="$style['review-footer']">
            <span>{{ review.created_at | relativeTime(lang) }}</span>
        </div>

    </div>

</template>

<style module lang="scss">

    .review {
        background: $color-white;
        border-style: solid;
        min-width: 100px;
        overflow: hidden;
        position: relative;
        text-align: left;
    }

    .review-body {
        display: flex;
        flex-direction: column;
        text-align: left;
        gap: 15px;
        .stars {
            align-items: center;
            display: flex;
            justify-content: space-between;
            line-height: normal;
            margin-bottom: 15px;
            .icon {
                font-size: 16px;
                font-style: normal;
            }
        }
        .text {
            font-family: var(--font);
            font-size: 15px;
            font-weight: 500;
            color: $color-text;
            line-height: 1.4em;
            overflow-wrap: break-word;
            text-align: left;
            &::before {
                content: none;
            }
            a {
                color: $color-text;
                text-decoration: underline;
            }
        }
        .author {
            align-items: center;
            display: flex;
            .name {
                font-family: var(--font);
                font-size: 15px;
                font-weight: 600;
                line-height: normal;
            }
            .avatar {
                flex-shrink: 0;
                margin-right: 10px;
            }
        }
    }

    .review-footer {
        color: $color-muted;
        font-family: var(--font);
        font-size: 13px;
        font-weight: 500;
        line-height: normal;
        margin-top: 15px;
        text-align: left;
    }

</style>

<script>

import nl2br from 'nl2br';

export default {

    props: {
        review: {
            type: Object,
            required: true,
        },
        lang: {
            type: String,
            default: 'en',
        },
        avatar: {
            type: Boolean,
            default: true,
        },
        date: {
            type: Boolean,
            default: true,
        },
        borderColor: {
            type: String,
            default: '#ccc',
        },
        borderWidth: {
            type: String,
            default: '1px',
        },
        borderRadius: {
            type: String,
            default: '5px',
        },
        dropShadow: {
            type: Boolean,
            default: false,
        },
        padding: {
            type: String,
            default: '20px',
        },
        quotes: {
            type: Boolean,
            default: false,
        },
        customerPosition: {
            type: String,
            default: 'bottom',
        },
    },

    data() {
        return {
            url: process.env.VUE_APP_URL,
            expanded: false,
        }
    },

    computed: {

        author() {
            return this.review.customer ?? this.review.reviewer;
        },

        source() {
            return this.review.source;
        },

        expandedText() {
            let text = this.review.review;
            if (!text) return null;
            if (this.quotes) {
                text = `&ldquo;${text}&rdquo;`;
            }
            return nl2br(text);
        },

        collapsedText() {
            let text = this.review.review_short;
            if (!text) return null;
            if (this.quotes) {
                text = `&ldquo;${text}&rdquo;`;
            }
            return nl2br(text).trim();
        },

        canExpand() {
            return this.review.review.length !== this.review.review_short.length;
        },

        hasAuthor() {
            return this.author?.display_name;
        },

    },

    methods: {

        expand() {
            this.expanded = true;
            this.$emit('changed');
        },

        collapse() {
            this.expanded = false;
            this.$emit('changed');
        },

    },

}

</script>