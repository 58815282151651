import { store } from '@/store';
import Vue from 'vue';
import VueCustomElement from 'vue-custom-element';
import App from '@js/App';

import '@/filters';
import '@sass/app.scss';

// Vue config.
Vue.use(VueCustomElement);
Vue.config.devtools = true;
Vue.config.productionTip = false;
Vue.config.silent = false;
Vue.prototype.$brand = process.env.VUE_APP_BRAND;
Vue.prototype.$apiUrl = process.env.VUE_APP_API_URL;
Vue.prototype.$appUrl = process.env.VUE_APP_APP_URL;

// Components.
Vue.component('v-avatar', require('@cmp/Avatar').default);
Vue.component('v-badge', require('@cmp/Badge').default);
Vue.component('v-review', require('@cmp/Review').default);

// Widgets.
Vue.component('v-widget-spotlight', require('@widgets/WidgetSpotlight').default);
Vue.component('v-widget-wall', require('@widgets/WidgetWall').default);

// Widget embed.
App.store = store;
let el = document.querySelector('saastic-root');
if (el) {
    Vue.customElement('saastic-root', App);
} else {
    Vue.customElement('reviews-root', App);
}

// Init embed code
if (typeof window.saastic !== 'undefined') {
    // eslint-disable-next-line
    window.saastic=window.saastic||function(){(saastic.q=saastic.q||[]).push(arguments)};
} else {
    // eslint-disable-next-line
    window.reviews=window.reviews||function(){(reviews.q=reviews.q||[]).push(arguments)};
}