// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".c123c1{background:#fff;border-style:solid;min-width:200px;overflow:hidden;position:relative;text-align:left;width:100%}.b9af72{-webkit-box-orient:vertical;-webkit-box-direction:normal;-ms-flex-direction:column;flex-direction:column;text-align:left;gap:15px}.b9af72,.b9af72 .f29512{display:-webkit-box;display:-ms-flexbox;display:flex}.b9af72 .f29512{-webkit-box-align:center;-ms-flex-align:center;align-items:center;-webkit-box-pack:justify;-ms-flex-pack:justify;justify-content:space-between;line-height:normal;margin-bottom:15px}.b9af72 .f29512 .f36d3a{font-size:16px;font-style:normal}.b9af72 .d3ca6c{font-family:var(--font);font-size:15px;font-weight:500;color:#333;line-height:1.4em;overflow-wrap:break-word;text-align:left}.b9af72 .d3ca6c:before{content:none}.b9af72 .d3ca6c a{color:#333;text-decoration:underline}.b9af72 .f7d0ca{-webkit-box-align:center;-ms-flex-align:center;align-items:center;display:-webkit-box;display:-ms-flexbox;display:flex}.b9af72 .f7d0ca .b4c87f{font-family:var(--font);font-size:15px;font-weight:600;line-height:normal}.b9af72 .f7d0ca .b9bbdf{margin-right:10px}.dabc29{color:#999;font-family:var(--font);font-size:13px;font-weight:500;line-height:normal;margin-top:15px;text-align:left}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"review": "c123c1",
	"review-body": "b9af72",
	"stars": "f29512",
	"icon": "f36d3a",
	"text": "d3ca6c",
	"author": "f7d0ca",
	"name": "b4c87f",
	"avatar": "b9bbdf",
	"review-footer": "dabc29"
};
export default ___CSS_LOADER_EXPORT___;
