var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:_vm.$style['review'],style:({
    'border-color': _vm.borderColor,
    'border-width': _vm.borderWidth,
    'border-radius': _vm.borderRadius,
    'box-shadow': _vm.dropShadow ? '0 2px 5px rgba(0,0,0,0.3)' : 'none',
    'padding': _vm.padding,
})},[_c('div',{class:_vm.$style['review-body'],style:({ 'flex-direction': _vm.customerPosition === 'top' ? 'column-reverse' : 'column' })},[_c('div',[_c('div',{class:_vm.$style['stars']},[_c('div',_vm._l((5),function(i){return _c('span',{key:'star-' + i,class:[_vm.$style['icon'], 'fas fa-star'],style:({ 'color': i <= _vm.review.rating.score ? _vm.review.rating.color : 'rgba(0,0,0,0.2)' })})}),0),(_vm.source)?_c('div',{class:_vm.$style['source'],attrs:{"title":_vm.source.name}},[(_vm.source.icon)?_c('img',{class:_vm.$style['icon'],attrs:{"src":_vm.source.icon.link,"height":"20","width":"20"}}):(_vm.source.sprite)?_c('span',{class:[_vm.$style['icon'], _vm.source.sprite],style:({ 'color': _vm.source.color })}):_vm._e()]):_vm._e()]),(_vm.canExpand)?_c('div',{class:_vm.$style['text']},[(!_vm.expanded)?[_c('span',{domProps:{"innerHTML":_vm._s(_vm.collapsedText)}}),_c('span',[_vm._v("... "),_c('a',{staticClass:"more-or-less",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.expand.apply(null, arguments)}}},[_vm._v("more")])])]:[_c('span',{domProps:{"innerHTML":_vm._s(_vm.expandedText)}}),_c('span',[_vm._v(" "),_c('a',{staticClass:"more-or-less",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.collapse.apply(null, arguments)}}},[_vm._v("less")])])]],2):_c('div',{class:_vm.$style['text']},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.expandedText)}})])]),(_vm.hasAuthor)?_c('div',{class:_vm.$style['author']},[(_vm.avatar)?_c('v-avatar',{class:_vm.$style['avatar'],attrs:{"name":_vm.author.display_name,"color":_vm.author.color,"url":_vm.author.photo ? _vm.author.photo.link : _vm.author.gravatar,"size":50,"radius":25}}):_vm._e(),_c('div',{class:_vm.$style['name']},[_vm._v(_vm._s(_vm.author.display_name))])],1):_vm._e()]),(_vm.date)?_c('div',{class:_vm.$style['review-footer']},[_c('span',[_vm._v(_vm._s(_vm._f("relativeTime")(_vm.review.created_at,_vm.lang)))])]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }