import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex)
Vue.config.devtools = true;

export const store = new Vuex.Store({
    state: {
        settings: localStorage.getItem('mgr_settings') ? JSON.parse(localStorage.getItem('mgr_settings')) : {},
    },
    mutations: {
        setSettings(state, settings) {
            state.settings = settings;
            localStorage.setItem('mgr_settings', JSON.stringify(settings));
        },
    },
    getters: {
        settings: state => {
            return state.settings;
        },
    },
})
