import moment, { lang } from 'moment';
import numeral from 'numeral';
import Vue from 'vue';

Vue.filter('truncate', function(val, len, suffix = '...') {
    if (val === undefined || val === null || val === '') return '';
    return val.length > len ? val.substring(0, len) + suffix : val;
});

Vue.filter('date', function(val, format = 'MMM D, Y') {
    return val ? moment.unix(val).format(format) : '';
});

Vue.filter('dateTime', function(val, format = 'MMM D, Y h:mm A') {
    return val ? moment.unix(val).format(format) : '';
});

Vue.filter('relativeTime', function(val, lang = 'en') {
    moment.locale(lang);
    const relativeTime = val ? moment.unix(val).fromNow() : '';
    moment.locale('en');
    return relativeTime;
});

Vue.filter('numeral', function(val, format = '0,0') {
    return numeral(String(val)).format(format);
});