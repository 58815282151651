<template>

    <div :class="$style['avatar']" :title="name" :style="styles">
        <span :style="initialStyles">{{ initials | truncate(2, '') }}</span>
        <div :class="$style['photo']" :style="photoStyles"></div>
    </div>

</template>

<style module lang="scss">

    .avatar {
        display: grid;
        overflow: hidden;
        .photo {
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
            grid-column-start: 1;
            grid-row-start: 1;
            overflow: hidden;
        }
        span {
            color: $color-white;
            display: grid;
            font-family: var(--font);
            font-size: 9px;
            font-weight: 700;
            grid-column-start: 1;
            grid-row-start: 1;
            line-height: 0;
            place-items: center;
            text-transform: uppercase;
            user-select: none;
        }
    }

</style>

<script>

import initials from 'initials';

export default {

    props: {
        name: {
            type: String,
        },
        color: {
            type: String,
            default: '#000',
        },
        size: {
            type: Number,
            default: 40,
        },
        radius: {
            type: Number,
            default: 3,
        },
        border: {
            type: Number,
            default: 1,
        },
        url: {
            type: String,
        },
    },

    computed: {

        initials() {
            return this.name ? initials(this.name) : '?';
        },

        styles() {
            return {
                'border-radius': `${this.radius}px`,
            }
        },

        fontSize() {
            if (this.size <= 40) return '9px';
            if (this.size <= 50) return '14px';
            return '20px';
        },

        initialStyles() {
            return {
                'background-color': this.color,
                'border-radius': `${this.radius}px`,
                'font-size': this.fontSize,
                'height': `${this.size}px`,
                'width': `${this.size}px`,
            }
        },

        photoStyles() {
            return {
                'background-image': this.url ? `url('${this.url}')` : '',
                'border-radius': `${this.radius}px`,
                'height': `${this.size}px`,
                'width': `${this.size}px`,
            }
        },

    },

}

</script>