// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".d2af54{margin:0 -15px -15px 0}.d2af54>div{padding:0 15px 15px 0;width:25%}@media(max-width:1200px){.d2af54>div{width:33.333%}}@media(max-width:992px){.d2af54>div{width:50%}}@media(max-width:768px){.d2af54>div{width:100%}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"reviews": "d2af54"
};
export default ___CSS_LOADER_EXPORT___;
