<script>

export default {

    methods: {

        setFont(font) {
            let link = document.createElement('link');
            link.href = `https://fonts.googleapis.com/css?family=${font.replace(/ /g, '+')}:400,500,600&display=swap`;
            link.rel = 'stylesheet';
            document.head.appendChild(link);
            this.$el.style.setProperty('--font', `${font}, Arial, sans-serif`);
        },

    }

}

</script>